<template>
  <b-card :header="class_name">
    <div class="chart-wrapper">
      <q-linear-progress color="blue-grey-6" indeterminate :class="{ invisible: !loading }" />
      <!-- <QuotaGraph chartId="chart-line-01" :chartData="chartData" :width="750" :height="250" /> -->

      <!-- :pagination.sync="pagination" -->
      <!-- :allow_searchbar="false" -->
      <Search
        ref="searchRef"
        :default_per_page="default_per_page"
        :class_name="this.class_name"
        :stateName="'quota_page_'"
        :small="small"
        :allow_bottom_pagination="false"
        :allow_relationships_filter="true"
        :allow_predefined_filter="true"
        :allow_properties_filter="true"
        :allow_json_filter="true"
        :allow_select_fields="true"
        :allow_map="false"
        :enable_map_default="false"
        :enable_table="false"
        :_index_params="index_params_"
        @update:pagination="
          pagination => {
            convertForGraph(pagination.data);
          }
        "
      >
        <template v-slot:search_top_slot="{}">
          <b-row class="toggles">
            <b-col cols="6" sm="4" md="4" xl="3" class="my-1">
              <b-form-group label="Linear/Series" :label-cols="12" :label-cols-sm="8">
                <c-switch
                  class="mx-2"
                  v-model="is_compact_mode"
                  color="success"
                  :size="small ? 'sm' : null"
                  label
                  outline
                  :dataOn="'\u2713'"
                  :dataOff="'\u2715'"
                  :value="true"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <QuotaGraph chartId="chart-line-01" :chartData="chartData" :width="750" :height="250" :is_compact_mode="is_compact_mode" />
        </template>
      </Search>
    </div>
  </b-card>
</template>
<script>
import { Switch as cSwitch } from '@coreui/vue';
import { QLinearProgress, QSpinner } from 'quasar';
import { hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities';

export default {
  components: {
    QuotaGraph: () => import('./QuotaGraph'),
    QLinearProgress,
    QSpinner,
    hexToRgba,
    cSwitch,
    Search: () => import('@/descriptor/coreui/Search'),
  },
  data() {
    return {
      loading: null,
      is_compact_mode: true,
      chartData: {},
    };
  },
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    class_name: {
      type: String,
      required: true,
    },
    label_relationships: {
      type: Array,
      // required: true,
    },
    label_properties: {
      type: Array,
      // required: true,
    },
    color_relationship: {
      type: Object,
      required: false,
    },
    /* relationship_name: {
      type: String,
      required: true,
    },*/
    value_properties: {
      type: Array,
      required: true,
    },
    index_params: {
      type: Object,
    },
    default_per_page: {
      type: [Number, String],
      default: 'all',
    },
  },
  computed: {
    index_params_() {
      let index_params_ = {};
      let with_relationships = [];
      /* 
      with_relationships.push({
        from_class_name: this.class_name,
        relationship_name: this.relationship_name,
      });
 */
      _.each(this.label_relationships, label_relationship => {
        with_relationships.push({
          from_class_name: this.class_name,
          relationship_name: label_relationship['relationship_name'],
        });
      });
      index_params_ = {
        with_relationships: with_relationships,
      };

      index_params_ = _.merge(index_params_, this.index_params);

      return index_params_;
    },
    label_properties_reverse() {
      return this.label_properties.reverse();
    },
  },
  watch: {},
  created() {
    this.init();
  },
  methods: {
    init() {},
    extractValues(datasets, timeseries_values, timeseries_value, labels = []) {
      // console.log(timeseries_value)
      let dataset_datetime = timeseries_value['from_date'];
      // let dataset_remarks = timeseries_value['remarks'];

      // console.log('timeseries_value', timeseries_value);
      this.value_properties.forEach(value_property => {
        let dataset_value = timeseries_value[value_property['property_key']];

        if (!_.isNil(dataset_value)) {
          // console.log('labels', labels);
          let final_labels = _.clone(labels);

          this.label_properties_reverse.forEach(label_property => {
            final_labels.unshift(timeseries_value[label_property['property_key']]);
          });

          final_labels.push(value_property['label']);
          // console.log('final_labels', final_labels);

          // console.log(dataset_datetime);

          let dataset_label = final_labels.length ? final_labels.join(' - ') : dataset_value;

          let dataset_color_hex8 = '#CCCCCC';
          if (this.color_relationship) {
            let relation_color = _.get(timeseries_value[this.$d.getRelationAliasByNames(this.class_name, this.color_relationship['relationship_name'])], [
              0,
              this.color_relationship['property_key'],
            ]);

            if (relation_color) {
              dataset_color_hex8 = relation_color;
            }
          }

          let opacity = 50;
          let dataset_color_rgba = dataset_color_hex8 ? hexToRgba(dataset_color_hex8.substring(0, 7), opacity) : '#CCC';

          // console.log(dataset_datetime, dataset_label, dataset_value);

          let dataset = _.find(datasets, { label: dataset_label });

          if (!dataset) {
            datasets.push({
              label: dataset_label,
              backgroundColor: dataset_color_rgba,
              //   backgroundColor: dataset_color_hex8,
              // steppedLine: 'after',
              steppedLine: 'before',
              data: [
                {
                  x: dataset_datetime,
                  y: dataset_value,
                },
              ],
              hidden: value_property['hidden'] || false,
            });
          } else {
            let datum = {
              x: dataset_datetime,
              y: dataset_value,
            };
            if (!_.find(dataset.data, datum)) {
              dataset.data.push(datum);
            }
          }

          dataset = _.find(datasets, { label: dataset_label });
          let end_dataset_datetime = timeseries_value['to_date'];
          if (end_dataset_datetime) {
            let next_timeseries_value = _.find(timeseries_values, { from_date: end_dataset_datetime });
            if (next_timeseries_value) {
              console.log('has continuous end', timeseries_value, next_timeseries_value);
            } else {
              console.log('does not have continuous end', timeseries_value);
              //defaults to zero
              dataset.data.push({
                x: end_dataset_datetime,
                y: 0,
              });
            }
          }
        }
      });
    },
    extractLabelAndValues(datasets, label_relationships, timeseries_values, timeseries_value, labels = []) {
      let final_labels = null;
      // console.log(remaining_label_relationships, timeseries_value);

      let remaining_label_relationships = _.clone(label_relationships);
      if (!remaining_label_relationships) {
        this.extractValues(datasets, timeseries_values, timeseries_value);
      } else {
        let label_relationship = remaining_label_relationships.shift();

        if (label_relationship) {
          // console.log(label_relationship, timeseries_value);
          let relations = timeseries_value[this.$d.getRelationAliasByNames(this.class_name, label_relationship['relationship_name'])];
          // console.log(relations);

          let next_labels = _.clone(labels);

          if (relations.length) {
            let label = label_relationship['relationship_name']; //by default, use relationship name to show existence
            if (label_relationship['label']) {
              label = label_relationship['label']; //if label defined, use hard-coded label instead
            }

            let label_pushed = false;
            let value_pushed = false;
            _.each(relations, relation => {
              if (label_relationship['property_key']) {
                label = relation[label_relationship['property_key']]; //if property_key defined, use the relation's property as label instead
              }
              // console.log(next_labels, label, timeseries_value);
              if (label_relationship['property_key'] || !label_pushed) {
                //push label for each relation only if will change for each relation
                next_labels.push(label);
                label_pushed = true;
              }

              if (remaining_label_relationships.length > 0) {
                // console.log('continue', next_labels);
                this.extractLabelAndValues(datasets, remaining_label_relationships, timeseries_values, timeseries_value, next_labels);
              } else {
                // console.log('end', next_labels);
                if (label_relationship['property_key'] || !value_pushed) {
                  this.extractValues(datasets, timeseries_values, timeseries_value, next_labels);
                  value_pushed = true;
                }
              }
            });
          } else {
            // next_labels.push('XX');
            if (remaining_label_relationships && remaining_label_relationships.length > 0) {
              // console.log('continue', next_labels);
              this.extractLabelAndValues(datasets, remaining_label_relationships, timeseries_values, timeseries_value, next_labels);
            } else {
              // console.log('end', next_labels);
              this.extractValues(datasets, timeseries_values, timeseries_value, next_labels);
            }
          }

          // labels = []; // clear
        }
      }
    },
    convertForGraph(timeseries_values) {
      // console.log(timeseries_values);
      let datasets = [];

      _.each(timeseries_values, timeseries_value => {
        this.extractLabelAndValues(datasets, this.label_relationships, timeseries_values, timeseries_value);
      });

      console.log(datasets);

      datasets.forEach(dataset => {
        //sort data by date
        //ref: https://stackoverflow.com/a/40965804/3553367
        dataset.data.sort(function compare(a, b) {
          var dateA = new Date(a.x);
          var dateB = new Date(b.x);
          return dateA - dateB;
        });
      });

      this.chartData = {
        datasets: datasets,
      };
    },
  },
};
</script>
